import { ReactElement } from "react";
import css from "./index.module.css";

export const code404TestId = "code404";

export default function Code404(): ReactElement {
  return (
    <div
      className={css.code}
      data-cy="code-404-block"
      data-testid={code404TestId}
    >
      <code>
        ____________________________________________________________________
        ____________________________________________________________________
        ____________________________________________________________________
        ____________/\\\_________/\\\\\\\________________/\\\_______________
        ___________/\\\\\_______/\\\/////\\\____________/\\\\\______________
        __________/\\\/\\\______/\\\____\//\\\_________/\\\/\\\_____________
        _________/\\\/\/\\\_____\/\\\_____\/\\\_______/\\\/\/\\\____________
        ________/\\\/__\/\\\_____\/\\\_____\/\\\_____/\\\/__\/\\\___________
        _______/\\\\\\\\\\\\\\\\__\/\\\_____\/\\\___/\\\\\\\\\\\\\\\\_______
        _______\///////////\\\//___\//\\\____/\\\___\///////////\\\//_______
        __________________\/\\\______\///\\\\\\\/______________\/\\\________
        ___________________\///_________\///////________________\///________
        ____________________________________________________________________
        ____________________________________________________________________
        ____________________________________________________________________
      </code>
    </div>
  );
}
