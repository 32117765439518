import Page404 from "@components/Page404";
import Page500 from "@components/Page500";
import { ReactNode } from "react";
import SignedOutLayout from "../SignedOutLayout";
import css from "./index.module.css";

type Props = {
  error?: Error;
  title?: string;
  children?: ReactNode;
  type?: 404 | 500;
};

export default function ErrorLayout({ type = 404, ...props }: Props) {
  return (
    <SignedOutLayout>
      <div className={css.container}>
        <div className={css.inner}>
          {type === 404 ? <Page404 {...props} /> : <Page500 {...props} />}
        </div>
      </div>
    </SignedOutLayout>
  );
}
