import { ReactElement } from "react";
import css from "./index.module.css";

// Generated from https://patorjk.com/software/taag/#p=display&f=Slant%20Relief&t=500
export default function Code500(): ReactElement {
  return (
    <div className={css.code} data-cy="code-500-block">
      <code>
        ____________________________________________________________________
        ____________________________________________________________________
        ____________________________________________________________________
        _______/\\\\\\\\\\\\\\\______/\\\\\\\________/\\\\\\\_______________
        _______\/\\\///////////_____/\\\/////\\\____/\\\/////\\\____________
        ________\/\\\_______________/\\\____\//\\\__/\\\____\//\\\__________
        _________\/\\\\\\\\\\\\_____\/\\\_____\/\\\_\/\\\_____\/\\\_________
        __________\////////////\\\___\/\\\_____\/\\\_\/\\\_____\/\\\________
        ______________________\//\\\__\/\\\_____\/\\\_\/\\\_____\/\\\_______
        ____________/\\\________\/\\\__\//\\\____/\\\__\//\\\____/\\\_______
        ____________\//\\\\\\\\\\\\\/____\///\\\\\\\/____\///\\\\\\\/_______
        ______________\/////////////________\///////________\///////________
        ____________________________________________________________________
        ____________________________________________________________________
        ____________________________________________________________________
      </code>
    </div>
  );
}
