import Link from "@components/links/Link";
import { ErrorMsg } from "@dolthub/react-components";
import { ApolloErrorType } from "@lib/errors/types";
import { ReactNode } from "react";
import Code500 from "./Code500";
import css from "./index.module.css";

type Props = {
  title?: string;
  children?: ReactNode;
  error?: ApolloErrorType;
};

const defaultTitle = "500: Internal server error";
const errorText = "Sorry, but there was an error loading this page.";

export default function Page500({ title = defaultTitle, ...props }: Props) {
  return (
    <div className={css.page} data-cy="500-page">
      <h1>{title}</h1>
      {!props.error && <Code500 />}
      {props.children ? (
        <div>{props.children}</div>
      ) : (
        <div className={css.text}>
          <p>{errorText}</p>
          <p data-cy="500-page-links">
            Return to our <Link href="/">homepage</Link>, or{" "}
            <Link href="/contact">reach out to us</Link> if you can&apos;t find
            what you&apos;re looking for.
          </p>
        </div>
      )}
      {props.error && (
        <div className={css.error}>
          <span>Additional error details: </span>
          <ErrorMsg err={props.error} />
        </div>
      )}
    </div>
  );
}
